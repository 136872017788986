import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'

function Community() {
    const { t } = useTranslation()

    return (
        <div>
            <Navbar
                menu1={t('navbar.menu1') || 'Communities'}
                menu2={t('navbar.menu2') || 'Careers'}
                menu3={t('navbar.menu3') || '4 Investors'}
                menu4={t('navbar.menu4') || 'Contact Us'}
            />

            <Footer
                subtitle={t('footerhome.subtitle')}
                title={t('footerhome.title')}
                description={t('footerhome.description')}
                cta={t('footerhome.cta')}
                swissfooter={t('footerhome.swissfooter')}
                swissquality={t('footerhome.swissquality')}
                swissinnovation={t('footerhome.swissinnovation')}
                swissprecision={t('footerhome.swissprecision')}
                footertext={t('footerhome.footertext')}
            />
        </div>
    )
}

export default Community
