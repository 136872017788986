import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../styles/Navbar.css'
import LanguageSelectionPopup from './LanguageSelectionPopup'

const Navbar = ({ menu1, menu2, menu3, menu4 }) => {
    const [isSideNavActive, setIsSideNavActive] = useState(false)
    const [isLanguagePopupVisible, setIsLanguagePopupVisible] = useState(false)
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')

    const toggleSideNav = () => {
        setIsSideNavActive((prevState) => !prevState)
    }

    const hideSideNav = () => {
        setIsSideNavActive(false)
    }

    const toggleLanguagePopup = () => {
        setIsLanguagePopupVisible((prevState) => !prevState)
    }

    const closeLanguagePopup = () => {
        setIsLanguagePopupVisible(false)
    }

    // Theme switcher logic
    const switchTheme = (theme) => {
        setTheme(theme)
        localStorage.setItem('theme', theme)

        if (theme === 'dark') {
            document.body.setAttribute('data-theme', 'dark')
        } else {
            document.body.removeAttribute('data-theme')
        }
    }

    // Set theme on component mount
    useEffect(() => {
        switchTheme(theme)
    }, [theme])

    // Determine which logo to show based on theme
    const logoSrc =
        theme === 'dark'
            ? './assets/vector/logodark.svg'
            : './assets/vector/logolight.svg'

    return (
        <div id="navbar">
            <div className="container-fluid">
                <Link to="/" className="logo">
                    <img src={logoSrc} alt="logo" />
                </Link>

                {/* Language icon */}
                <div className="translation-icon">
                    <img
                        src="./assets/vector/languagevector.svg"
                        alt="language-icon"
                        onClick={toggleLanguagePopup}
                    />
                    {isLanguagePopupVisible && (
                        <LanguageSelectionPopup onClose={closeLanguagePopup} />
                    )}
                </div>

                {/* Theme switcher (toggle button) */}
                <div className="theme-switcher">
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={theme === 'dark'}
                            onChange={() =>
                                switchTheme(theme === 'dark' ? 'light' : 'dark')
                            }
                        />
                        <span className="slider"></span>
                    </label>
                </div>

                {/* Hamburger menu */}
                <div
                    onClick={toggleSideNav}
                    id="hamburger"
                    className="d-block d-md-none"
                >
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                {/* Navbar menu items */}
                <ul className={`nav${isSideNavActive ? ' active' : ''}`}>
                    <div id="close" onClick={toggleSideNav}>
                        &times;
                    </div>

                    <li className="nav-item">
                        <Link onClick={hideSideNav} to="/contact">
                            {menu3}
                        </Link>
                    </li>

                    <li className="nav-item">
                        <a
                            onClick={hideSideNav}
                            className="btn-header"
                            href="https://my.lvdere.com"
                        >
                            My Lvdere
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar
