import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import SwiperCore, { Navigation } from 'swiper'
SwiperCore.use([Navigation])

function CommunitySelect({
    title,
    service1title,
    service1description,
    service2title,
    service2description,
    service3title,
    service3description,
    service4title,
    service4description,
    service5title,
    service5description,
    step1title,
    step1description,
    step2title,
    step2description,
    step3title,
    step3description,
    ctalink,
    cta,
}) {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)

    const [swiperIndex, setSwiperIndex] = React.useState(0)
    const [activeCardIndex, setActiveCardIndex] = useState(null)

    const swiperData = [
        {
            title: service1title,
            description: service1description,
            img: './assets/vector/community-web3-starterpack.svg',
        },
        {
            title: service2title,
            description: service2description,
            img: './assets/vector/community-academy.svg',
        },
        {
            title: service3title,
            description: service3description,
            img: './assets/vector/community-exclusive.svg',
        },
        {
            title: service4title,
            description: service4description,
            img: './assets/vector/community-mentoring.svg',
        },
        {
            title: service5title,
            description: service5description,
            img: './assets/vector/community-support.svg',
        },
    ]

    const steps = [
        { title: step1title, description: step1description },
        { title: step2title, description: step2description },
        { title: step3title, description: step3description },
    ]

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth > 1000) return // Skip scroll logic on desktop

            const cards = document.querySelectorAll('.services_selector_card')
            const viewportHeight = window.innerHeight

            cards.forEach((card, index) => {
                const rect = card.getBoundingClientRect()
                const cardCenter = rect.top + rect.height / 2

                if (
                    cardCenter >= viewportHeight / 3 &&
                    cardCenter <= (2 * viewportHeight) / 3
                ) {
                    setActiveCardIndex(index)
                }
            })
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <div className="services-container">
            <div id="services">
                <img
                    src="./assets/vector/bg-services-line.svg"
                    className="line"
                    alt="bg"
                />
                <div className="container-fluid">
                    <img
                        src="./assets/vector/bg-services.svg"
                        className="stroke"
                        alt="bg"
                    />

                    <h2 className="text-center mb-5 upper">{title}</h2>

                    <div className="services_selector upper">
                        <div className="services_selector_left">
                            <button
                                className="swiper_navigation_btn_left"
                                ref={navigationPrevRef}
                            >
                                <img
                                    src="./assets/img/btn-left.png"
                                    alt="Previous"
                                />
                            </button>
                            <button
                                className="swiper_navigation_btn_right"
                                ref={navigationNextRef}
                            >
                                <img
                                    src="./assets/img/btn-right.png"
                                    alt="Next"
                                />
                            </button>

                            <Swiper
                                slidesPerView={1}
                                spaceBetween={0}
                                onSlideChange={(e) =>
                                    setSwiperIndex(e.activeIndex)
                                }
                                onSwiper={(swiper) => {
                                    setTimeout(() => {
                                        swiper.params.navigation.prevEl =
                                            navigationPrevRef.current
                                        swiper.params.navigation.nextEl =
                                            navigationNextRef.current
                                        swiper.navigation.destroy()
                                        swiper.navigation.init()
                                        swiper.navigation.update()
                                    })
                                }}
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                }}
                            >
                                {swiperData.map((item, idx) => (
                                    <SwiperSlide key={idx}>
                                        <h3 className="mb-3">{item.title}</h3>
                                        <p>{item.description}</p>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div className="services_selector_right">
                            <img
                                src={swiperData[swiperIndex]?.img}
                                alt="Service"
                            />
                        </div>
                    </div>

                    <div className="services_selector_cards">
                        {steps.map((step, idx) => (
                            <div
                                key={idx}
                                className={`services_selector_card ${
                                    activeCardIndex === idx ? 'active' : ''
                                }`}
                            >
                                <p className="services_selector_card_title mb-5px fs-16px weight-7">
                                    /{`0${idx + 1}`}
                                </p>
                                <p className="fs-32px weight-7 mb-10px">
                                    {step.title}
                                </p>
                                <p className="fs-18px">{step.description}</p>
                            </div>
                        ))}
                    </div>

                    <a href={ctalink}>
                        <button className="services_selector_btn fs-16px weight-7">
                            {cta}
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CommunitySelect
