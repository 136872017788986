import React from 'react'
import Fade from 'react-reveal/Fade'
import '../../styles/axrestaurant/axrestaurant-0903.css'

const AXRestaurant0903 = () => {
    const shareOnWhatsApp = () => {
        const message = encodeURIComponent(
            '🔥 Gesund essen gibt es bei Chaima Takeaway! 🌱 Besuche uns in Zürich und genieße unsere frischen und gesunden Gerichte! 🥗🐶 Wir sind hundefreundlich!'
        )
        window.open(`https://wa.me/?text=${message}`, '_blank')
    }

    return (
        <div className="chaima-page">
            {/* Header Section */}
            <div className="chaima-header">
                <Fade>
                    <img
                        src="/assets/img/axrestaurant/axrestaurant0903-chaimathaitakeaway.png"
                        alt="Chaima Takeaway Logo"
                        className="chaima-logo"
                    />

                    <p>Lägernstrasse 32, 8037 Zürich Wipkingen</p>
                </Fade>
            </div>

            {/* Links Section */}
            <section className="chaima-links">
                <Fade cascade>
                    <h2>Merci Vielmals</h2>
                    <a
                        href="https://search.google.com/local/writereview?placeid=ChIJp5yuPG8KkEcRjzzUjd86UEE"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="chaima-btn">
                            Schreiben Sie eine Rezension auf Google ⭐⭐⭐⭐⭐
                        </button>
                    </a>

                    <a
                        href="https://maps.app.goo.gl/sAvwfRspgwquXhabA?g_st=iwb"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="chaima-btn">Wegbeschreibung</button>
                    </a>
                </Fade>
            </section>

            {/* Opening Hours */}
            <section className="chaima-hours">
                <Fade cascade>
                    <h2>Öffnungszeiten</h2>
                    <p>
                        <strong>Montag - Freitag:</strong> 11:30 - 14:00 Uhr
                    </p>
                    <p>
                        <strong>Montag - Freitag:</strong> 18:00 - 21:00 Uhr
                    </p>
                </Fade>
            </section>

            {/* Share and Discount */}
            <section className="chaima-discount">
                <Fade>
                    <h2>Genussvoll & Gesund bei Chaima!🌱</h2>
                    <p>
                        Teile Chaima Takeaway mit einem Freund und entdeckt
                        gemeinsam unsere leckeren, gesunden Gerichte!
                    </p>
                    <button
                        className="chaima-btn whatsapp-share"
                        onClick={shareOnWhatsApp}
                    >
                        📲 Mit einem Freund auf WhatsApp teilen
                    </button>
                </Fade>
            </section>

            {/* Dog Friendly */}
            <section className="chaima-dog-friendly">
                <Fade>
                    <h2>🐶 Hunde sind willkommen!</h2>
                    <p>
                        Wir freuen uns, Ihre vierbeinigen Freunde zu begrüßen.
                    </p>
                </Fade>
            </section>

            {/* Contact Info */}
            <section className="chaima-contact">
                <Fade cascade>
                    <h2>Adresse & Kontakt</h2>
                    <p>
                        <strong>Adresse:</strong> Lägernstrasse 32, 8037 Zürich
                        Wipkingen
                    </p>
                    <p>
                        <strong>Telefon:</strong> +41 (0)43 255 06 60
                    </p>
                    <p>
                        <strong>E-Mail:</strong>{' '}
                        <a href="mailto:chaimatakeaway@gmail.com">
                            chaimatakeaway@gmail.com
                        </a>
                    </p>
                </Fade>
            </section>

            {/* Footer */}
            <footer className="chaima-footer">
                <Fade>
                    <p>
                        Die Digitalisierung dieses Restaurants wird gesponsert
                        von
                        <a
                            href="https://lvdere.odoo.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Lvdere
                        </a>
                    </p>
                </Fade>
            </footer>
        </div>
    )
}

export default AXRestaurant0903
