import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Home from './pages/Home'
import Community from './pages/Community'
import Careers from './pages/Careers'
import Contact from './pages/Contact'
import Investors from './pages/Investors'
import PrivacyPolicy from './pages/PrivacyPolicy'
import AXRestaurant0901 from './pages/axrestaurant/axrestaurant-0901'
import AXRestaurant0902 from './pages/axrestaurant/axrestaurant-0902'
import AXRestaurant0903 from './pages/axrestaurant/axrestaurant-0903'
import AXRestaurant0904 from './pages/axrestaurant/axrestaurant-0904'
import AXRestaurant0905 from './pages/axrestaurant/axrestaurant-0905'
import AXRestaurant0906 from './pages/axrestaurant/axrestaurant-0906'
import AXRestaurant0907 from './pages/axrestaurant/axrestaurant-0907'
import AXRestaurant0908 from './pages/axrestaurant/axrestaurant-0908'
import AXRestaurant0909 from './pages/axrestaurant/axrestaurant-0909'
import AXRestaurant0910 from './pages/axrestaurant/axrestaurant-0910'

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/community" element={<Community />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/investors" element={<Investors />} />
                <Route
                    path="/axrestaurant-0901"
                    element={<AXRestaurant0901 />}
                />
                <Route
                    path="/axrestaurant-0902"
                    element={<AXRestaurant0902 />}
                />

                <Route
                    path="/axrestaurant-0902"
                    element={<AXRestaurant0902 />}
                />
                <Route
                    path="/axrestaurant-0903"
                    element={<AXRestaurant0903 />}
                />

                <Route
                    path="/axrestaurant-0904"
                    element={<AXRestaurant0904 />}
                />

                <Route
                    path="/axrestaurant-0905"
                    element={<AXRestaurant0905 />}
                />

                <Route
                    path="/axrestaurant-0906"
                    element={<AXRestaurant0906 />}
                />
                <Route
                    path="/axrestaurant-0907"
                    element={<AXRestaurant0907 />}
                />

                <Route
                    path="/axrestaurant-0908"
                    element={<AXRestaurant0908 />}
                />

                <Route
                    path="/axrestaurant-0909"
                    element={<AXRestaurant0909 />}
                />

                <Route
                    path="/axrestaurant-0910"
                    element={<AXRestaurant0910 />}
                />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
        </Router>
    )
}

export default App
