import React from 'react'
import Fade from 'react-reveal/Fade'
import '../../styles/axrestaurant/axrestaurant-0906.css'

const AXRestaurant0906 = () => {
    const shareOnWhatsApp = () => {
        const message = encodeURIComponent(
            '🔥 Experience the best drinks and aperitifs at Züri Bar! 🍸 Check it out now: https://zuribar.ch/'
        )
        window.open(`https://wa.me/?text=${message}`, '_blank')
    }

    return (
        <div className="zuribar-page">
            {/* Header Section */}
            <div className="zuribar-header">
                <Fade>
                    <div className="zuribar-logo-container">
                        <img
                            src="/assets/img/axrestaurant/axrestaurant0906-zuribar/axrestaurant0906-zuribar.png"
                            alt="Zuri Bar Logo"
                            className="zuribar-logo"
                        />
                    </div>
                    <h3>150+ Natural Italian Wines</h3>
                    <p>Aperitivi from Grandma's Recipes</p>
                    <p>Niederdorfstrasse 24, 8001 Zürich, Switzerland</p>
                </Fade>
            </div>

            {/* Links Section */}
            <section className="zuribar-links">
                <Fade cascade>
                    <h2>Leave a Review</h2>
                    <a
                        href="https://search.google.com/local/writereview?placeid=ChIJzQ61NKigmkcRX1Bj26EX5F8"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="zuribar-btn">
                            Review on Google ⭐⭐⭐⭐⭐
                        </button>
                    </a>

                    <a
                        href="https://maps.app.goo.gl/QPRBDuJVYy1JKuoN6"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="zuribar-btn">Get Directions</button>
                    </a>
                    <a
                        href="https://www.instagram.com/zuribar1947"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="zuribar-btn">
                            Follow us on Instagram
                        </button>
                    </a>
                </Fade>
            </section>

            {/* Buy Wine Section */}
            <section className="zuribar-buy-wine">
                <Fade>
                    <h2>Buy Your Natural Wine 🍷</h2>
                    <p>Explore our selection of 150+ natural Italian wines.</p>
                    <a
                        href="https://vino-naturale.ch/en/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="zuribar-btn"
                    >
                        Buy Now
                    </a>
                </Fade>
            </section>

            {/* Share and Discount */}
            <section className="zuribar-discount">
                <Fade>
                    <h2>Invite a friend 🎉</h2>
                    <p>
                        Invite a friend to Züri Bar and enjoy together our
                        natural wines
                    </p>
                    <button
                        className="zuribar-btn whatsapp-share"
                        onClick={shareOnWhatsApp}
                    >
                        📲 Share with Your Friend on WhatsApp
                    </button>
                </Fade>
            </section>

            {/* Opening Hours */}
            <section className="zuribar-hours">
                <Fade cascade>
                    <h2>Opening Hours</h2>
                    <p>
                        <strong>Monday - Thursday:</strong> 17:00 – 24:00
                    </p>
                    <p>
                        <strong>Friday - Saturday:</strong> 17:00 - 2:00
                    </p>
                    <p>
                        <strong>Sunday:</strong> Closed
                    </p>
                </Fade>
            </section>

            {/* Footer */}
            <footer className="zuribar-footer">
                <Fade>
                    <p>
                        The Digitalization of this Bar is powered by
                        <a
                            href="https://lvdere.odoo.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Lvdere
                        </a>
                    </p>
                </Fade>
            </footer>
        </div>
    )
}

export default AXRestaurant0906
