import React from 'react'
import Fade from 'react-reveal/Fade'

const AXRestaurant0910 = () => {
    return (
        <div className="construction-page">
            <div className="container text-center">
                <Fade>
                    <img
                        src="./assets/vector/logolight.svg"
                        alt="Lvdere GmbH Logo"
                        className="logo"
                    />
                </Fade>
                <Fade cascade>
                    <h1 className="mt-4">We're Building Something Great</h1>
                    <p className="mt-3">
                        Our site is currently under construction. Stay tuned for
                        updates!
                    </p>
                </Fade>
                <Fade>
                    <a href="https://lvdere.odoo.com">
                        <button className="btn btn-primary mt-4">
                            Discover our Solutions on: Lvdere x Odoo
                        </button>
                    </a>
                </Fade>
            </div>
        </div>
    )
}

export default AXRestaurant0910
