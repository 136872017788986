import React from 'react'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import ServiceSelect from '../components/ServiceSelect'
import Portfolio from '../components/Portfolio'
import { useTranslation } from 'react-i18next'

/**This is the Homepage of our Website, we are using the i18n for translation */

function Home() {
    const { t } = useTranslation()

    // Dynamic courses data from translations
    const courses = [
        {
            title: 'ALEGRIA',
            subtitle: 'Community: AX Gastro',
            description: t('portfolioprojects.description1'),
            image1: './assets/img/AXGASTRO-ALEGRIA.jpg', // First image
            image2: './assets/img/AXGASTRO.jpg', // Second image
            ctalink1: 'https://alegria-restaurante-peruano.ch/',
            ctatext1: t('portfolioprojects.cta1text'),
            ctalink2: 'https://lvdere.odoo.com/',
            ctatext2: t('portfolioprojects.cta2text'),
        },
        {
            title: 'WEEBO',
            subtitle: 'Community: AX Mobile App',
            description: t('portfolioprojects.description2'),
            image1: './assets/img/AXMOBILEAPP-WEEBO.jpg', // First image
            image2: './assets/img/AXMOBILEAPP.jpg', // Second image
            ctalink1: 'https://weebo.cloud/#/',
            ctatext1: t('portfolioprojects.cta1text'),
            ctalink2: 'https://lvdere.odoo.com/',
            ctatext2: t('portfolioprojects.cta2text'),
        },
        {
            title: 'Mastrovito Consulting',
            subtitle: 'Community: AX Swiss StartUp',
            description: t('portfolioprojects.description4'),
            image1: './assets/img/AXSWISS-STARTUP-MASTROVITO.jpg', // First image
            image2: './assets/img/AXSWISS-STARTUP.jpg', // Second image
            ctalink1: 'https://mastrovito.foscaldi.ch/',
            ctatext1: t('portfolioprojects.cta1text'),
            ctalink2: 'https://lvdere.odoo.com/',
            ctatext2: t('portfolioprojects.cta2text'),
        },
        {
            title: '16X',
            subtitle: 'Community: AX Crypto & Mining',
            description: t('portfolioprojects.description3'),
            image1: './assets/img/AXCRYPTOMINING-16X.jpg', // First image
            image2: './assets/img/AXCRYPTOMINING.jpg', // Second image
            ctalink1: 'https://chat.whatsapp.com/F2orSvSqDpnB57CLseLp7X',
            ctatext1: t('portfolioprojects.cta1text'),
            ctalink2: 'https://lvdere.odoo.com/',
            ctatext2: t('portfolioprojects.cta2text'),
        },
    ]

    return (
        <div>
            <Navbar
                menu1={t('navbar.menu1') || 'Communities'}
                menu2={t('navbar.menu2') || 'Careers'}
                menu3={t('navbar.menu3') || '4 Investors'}
                menu4={t('navbar.menu4') || 'Contact Us'}
            />
            <Hero
                img="./assets/img/hero.png"
                dots={true}
                btnText={t('herohome.cta')}
                btnLink="https://my.lvdere.com/"
                title={t('herohome.title')}
                subtitle={t('herohome.description')}
            />

            <Portfolio
                title={t('portfolioprojects.title')}
                subtitle={t('portfolioprojects.subtitle')}
                courses={courses} // Pass the courses array directly here
            />

            <ServiceSelect
                title={t('serviceselectcommunity.title')}
                service1title={t('serviceselectcommunity.service1title')}
                service1description={t(
                    'serviceselectcommunity.service1description'
                )}
                service2title={t('serviceselectcommunity.service2title')}
                service2description={t(
                    'serviceselectcommunity.service2description'
                )}
                service3title={t('serviceselectcommunity.service3title')}
                service3description={t(
                    'serviceselectcommunity.service3description'
                )}
                service4title={t('serviceselectcommunity.service4title')}
                service4description={t(
                    'serviceselectcommunity.service4description'
                )}
                service5title={t('serviceselectcommunity.service5title')}
                service5description={t(
                    'serviceselectcommunity.service5description'
                )}
                step1title={t('serviceselectcommunity.step1title')}
                step1description={t('serviceselectcommunity.step1description')}
                step2title={t('serviceselectcommunity.step2title')}
                step2description={t('serviceselectcommunity.step2description')}
                step3title={t('serviceselectcommunity.step3title')}
                step3description={t('serviceselectcommunity.step3description')}
                cta={t('serviceselectcommunity.cta')}
            />

            <Footer
                subtitle={t('footerhome.subtitle')}
                title={t('footerhome.title')}
                description={t('footerhome.description')}
                cta={t('footerhome.cta')}
                swissfooter={t('footerhome.swissfooter')}
                swissquality={t('footerhome.swissquality')}
                swissinnovation={t('footerhome.swissinnovation')}
                swissprecision={t('footerhome.swissprecision')}
                footertext={t('footerhome.footertext')}
            />
        </div>
    )
}

export default Home
