import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import '../styles/LanguageSelectionPopup.css'

function LanguageSelectionPopup({ onClose }) {
    const { t, i18n } = useTranslation()
    const popupRef = useRef(null)

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
        onClose() // Close the popup
    }

    // Close popup when clicking outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose()
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [onClose])

    return (
        <div className="language-selection-popup-overlay">
            <div className="language-selection-popup" ref={popupRef}>
                <button className="close-popup-button" onClick={onClose}>
                    &times;
                </button>
                <ul>
                    <li className="popup-title">{t('translation.select')}</li>
                    <li>
                        <button onClick={() => changeLanguage('en')}>
                            <img
                                src="./assets/vector/flag-uk.svg"
                                alt="English"
                                className="flag-icon"
                            />
                            {t('translation.en')}
                        </button>
                    </li>
                    <li>
                        <button onClick={() => changeLanguage('es')}>
                            <img
                                src="./assets/vector/flag-spain.svg"
                                alt="Español"
                                className="flag-icon"
                            />
                            {t('translation.es')}
                        </button>
                    </li>
                    <li>
                        <button onClick={() => changeLanguage('de')}>
                            <img
                                src="./assets/vector/flag-germany.svg"
                                alt="Deutsch"
                                className="flag-icon"
                            />
                            {t('translation.de')}
                        </button>
                    </li>
                    <li>
                        <button onClick={() => changeLanguage('fr')}>
                            <img
                                src="./assets/vector/flag-france.svg"
                                alt="Français"
                                className="flag-icon"
                            />
                            {t('translation.fr')}
                        </button>
                    </li>
                    <li>
                        <button onClick={() => changeLanguage('it')}>
                            <img
                                src="./assets/vector/flag-italy.svg"
                                alt="Italiano"
                                className="flag-icon"
                            />
                            {t('translation.it')}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default LanguageSelectionPopup
