import React from 'react'
import Fade from 'react-reveal/Fade'
import '../../styles/axrestaurant/axrestaurant-0901.css'

const AXRestaurant0901 = () => {
    const shareOnWhatsApp = () => {
        const message = encodeURIComponent(
            '🔥 Enjoy authentic Peruvian cuisine at Alegria Restaurant! 🇵🇪 Get a 10% discount for you and your friend when you visit! 🌟 Book your table now: https://alegria-restaurante-peruano.ch/reservieren'
        )
        window.open(`https://wa.me/?text=${message}`, '_blank')
    }

    return (
        <div className="alegria-page">
            {/* Header Section */}
            <div className="alegria-header">
                <Fade>
                    <img
                        src="/assets/img/axrestaurant/axrestaurant0901-alegria.png"
                        alt="Alegria Logo"
                        className="alegria-logo"
                    />
                    <h3>Ceviche Bar - Restaurant</h3>
                    <p>Seestrasse 361, 8038 Zürich, Switzerland</p>
                </Fade>
            </div>

            {/* Links Section */}
            <section className="alegria-links">
                <Fade cascade>
                    <h2>Write a Review</h2>
                    <a
                        href="https://search.google.com/local/writereview?placeid=ChIJkzDJzScJkEcRU-xvW1YM5Ko"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="alegria-btn">
                            Review on Google ⭐⭐⭐⭐⭐
                        </button>
                    </a>

                    <a
                        href="https://g.co/kgs/hTg5s1R"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="alegria-btn">Get Directions</button>
                    </a>
                    <a
                        href="https://alegria-restaurante-peruano.ch/reservieren"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="alegria-btn">Book a Table</button>
                    </a>
                    <a
                        href="https://tinyurl.com/y6ldmr4t"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="alegria-btn">
                            Order on Uber Eats
                        </button>
                    </a>
                </Fade>
            </section>

            {/* Share and Discount */}
            <section className="alegria-discount">
                <Fade>
                    <h2>Get 10% Off! 🎉</h2>
                    <p>
                        Share Alegria with a friend and both of you get a 10%
                        discount on your next visit!
                    </p>
                    <button
                        className="alegria-btn whatsapp-share"
                        onClick={shareOnWhatsApp}
                    >
                        📲 Share with Your Friend on WhatsApp
                    </button>
                </Fade>
            </section>

            {/* Opening Hours */}
            <section className="alegria-hours">
                <Fade cascade>
                    <h2>Opening Hours</h2>
                    <p>
                        <strong>Monday:</strong> Closed
                    </p>
                    <p>
                        <strong>Tuesday:</strong> 11:00 AM – 2:00 PM, 5:00 PM –
                        10:00 PM
                    </p>
                    <p>
                        <strong>Wednesday:</strong> 11:00 AM – 2:00 PM, 5:00 PM
                        – 10:00 PM
                    </p>
                    <p>
                        <strong>Thursday:</strong> 11:00 AM – 2:00 PM, 5:00 PM –
                        10:00 PM
                    </p>
                    <p>
                        <strong>Friday:</strong> 11:00 AM – 10:00 PM
                    </p>
                    <p>
                        <strong>Saturday:</strong> 11:00 AM – 10:00 PM
                    </p>
                    <p>
                        <strong>Sunday:</strong> 11:00 AM – 10:00 PM
                    </p>
                </Fade>
            </section>

            {/* Footer */}
            <footer className="alegria-footer">
                <Fade>
                    <p>
                        The Digitalization of this Restaurant is sponsored by
                        <a
                            href="https://lvdere.odoo.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Lvdere
                        </a>
                    </p>
                </Fade>
            </footer>
        </div>
    )
}

export default AXRestaurant0901
