import React from 'react'
import Fade from 'react-reveal/Fade'
import '../../styles/axrestaurant/axrestaurant-0904.css'

const AXRestaurant0904 = () => {
    const shareOnWhatsApp = () => {
        const message = encodeURIComponent(
            '🔥 Experience the best drinks and aperitifs at Bar Aperto! 🍸 Check it out now: https://baraperto.ch/'
        )
        window.open(`https://wa.me/?text=${message}`, '_blank')
    }

    return (
        <div className="baraperto-page">
            {/* Header Section */}
            <div className="baraperto-header">
                <Fade>
                    <div className="baraperto-logo-container">
                        <img
                            src="/assets/img/axrestaurant/axrestaurant0904-baraperto.png"
                            alt="Bar Aperto Logo"
                            className="baraperto-logo"
                        />
                    </div>
                    <h3>150+ Natural Italian Wines</h3>
                    <p>Fresh Pasta & Aperitivi from Grandma's Recipes</p>
                    <p>Nordstrasse 205, 8037 Zürich</p>
                </Fade>
            </div>

            {/* Links Section */}
            <section className="baraperto-links">
                <Fade cascade>
                    <h2>Leave a Review</h2>
                    <a
                        href="https://search.google.com/local/writereview?placeid=ChIJSfczsm4KkEcRUR4-mgK9x2M"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="baraperto-btn">
                            Review on Google ⭐⭐⭐⭐⭐
                        </button>
                    </a>

                    <a
                        href="https://maps.app.goo.gl/b7zAy1EJfuwB1WTQ7"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="baraperto-btn">
                            Get Directions
                        </button>
                    </a>
                    <a
                        href="https://www.instagram.com/bar.aperto"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="baraperto-btn">
                            Follow us on Instagram
                        </button>
                    </a>
                </Fade>
            </section>

            {/* Buy Wine Section */}
            <section className="baraperto-buy-wine">
                <Fade>
                    <h2>Buy Your Natural Wine 🍷</h2>
                    <p>Explore our selection of 150+ natural Italian wines.</p>
                    <a
                        href="https://vino-naturale.ch/en/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="baraperto-btn"
                    >
                        Buy Now
                    </a>
                </Fade>
            </section>

            {/* Share and Discount */}
            <section className="baraperto-discount">
                <Fade>
                    <h2>Invite a friend 🎉</h2>
                    <p>
                        Invite a friend to Bar Aperto and enjoy together our
                        natural wines
                    </p>
                    <button
                        className="baraperto-btn whatsapp-share"
                        onClick={shareOnWhatsApp}
                    >
                        📲 Share with Your Friend on WhatsApp
                    </button>
                </Fade>
            </section>

            {/* Opening Hours */}
            <section className="baraperto-hours">
                <Fade cascade>
                    <h2>Opening Hours</h2>
                    <p>
                        <strong>Lunch Hours (Tue - Fri):</strong> 11:30 AM –
                        2:00 PM
                    </p>
                    <p>
                        <strong>Evening Hours (Tue - Sat):</strong> 5:00 PM –
                        12:00 AM
                    </p>
                    <p>
                        <strong>Sunday & Monday:</strong> Closed
                    </p>
                </Fade>
            </section>

            {/* Footer */}
            <footer className="baraperto-footer">
                <Fade>
                    <p>
                        The Digitalization of this Bar is powered by
                        <a
                            href="https://lvdere.odoo.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Lvdere
                        </a>
                    </p>
                </Fade>
            </footer>
        </div>
    )
}

export default AXRestaurant0904
