import React from 'react'
import Fade from 'react-reveal/Fade'
import '../../styles/axrestaurant/axrestaurant-0902.css'

const AXRestaurant0902 = () => {
    return (
        <div className="caterina-page">
            {/* Header Section */}
            <div className="caterina-header">
                <Fade>
                    <img
                        src="/assets/img/axrestaurant/caterinagastro-axrestaurant0902.webp"
                        alt="Caterina Logo"
                        className="caterina-logo"
                    />
                    <h1>Caterina</h1>
                    <h3>Restaurant - Pizzeria - Bar</h3>
                    <p>Zürcherstrasse 25, 8730 Uznach</p>
                </Fade>
            </div>

            {/* Links Section */}
            <section className="caterina-links">
                <Fade cascade>
                    <h2>Write us a Review</h2>
                    <a
                        href="http://g.page/r/Cc0MVjZgyUYYEBE/review"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="caterina-btn">
                            Review on Google ⭐⭐⭐⭐⭐
                        </button>
                    </a>

                    <a
                        href="https://maps.app.goo.gl/5whw2hU42DjpgnxU6"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="caterina-btn">Get Directions</button>
                    </a>
                    <a
                        href="https://www.just-eat.ch/speisekarte/caterina-bar-ristorante-pizzeria"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="caterina-btn">Order Online</button>
                    </a>
                    <a
                        href="https://www.caterina-gastro.ch/appointment/6"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="caterina-btn">Book a Table</button>
                    </a>
                </Fade>
            </section>

            {/* Opening Hours */}
            <section className="caterina-hours">
                <Fade cascade>
                    <h2>Opening Hours</h2>
                    <p>
                        <strong>Monday:</strong> Closed
                    </p>
                    <p>
                        <strong>Tuesday - Thursday:</strong> 9:00 - 21:30
                    </p>
                    <p>
                        <strong>Friday - Saturday:</strong> 9:00 - 23:00
                    </p>
                    <p>
                        <strong>Sunday:</strong> 11:00 - 15:00 / 17:00 - 21:00
                    </p>
                </Fade>
            </section>

            {/* Footer */}
            <footer className="caterina-footer">
                <Fade>
                    <p>
                        The Digitalization of this Restaurant is sponsored by
                        <a
                            href="https://lvdere.odoo.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Lvdere
                        </a>
                    </p>
                </Fade>
            </footer>
        </div>
    )
}

export default AXRestaurant0902
