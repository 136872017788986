import React from 'react'
import Slide from 'react-reveal/Slide'
import '../styles/Footer.css'

const Footer = ({
    title,
    subtitle,
    description,
    swissfooter,
    swissquality,
    swissinnovation,
    swissprecision,
    footertext,
    communitylink,
    cta,
}) => {
    return (
        <div id="footer">
            <div className="container-fluid">
                <Slide bottom cascade>
                    <div className="footer-content">
                        <small>{subtitle}</small>

                        <h2 className="mt-3 mb-4">{title}</h2>

                        <h6 className="mx-auto mb-5">{description}</h6>

                        <div>
                            <a href={communitylink}>
                                <button className="btn">{cta}</button>
                            </a>
                        </div>

                        <p className="copyright mt-5 mb-5">
                            &copy; COPYRIGHT 2023 Lvdere GmbH
                        </p>

                        <div className="proudly-swiss mt-5">
                            <img
                                src="./assets/vector/flag-switzerland.svg"
                                alt="Swiss flag"
                                className="swiss-flag"
                            />
                            <p className="swiss-message">{swissfooter}</p>
                            <div className="swiss-values">
                                <div className="value-item">
                                    <img
                                        src="./assets/vector/quality-icon.svg"
                                        alt="Quality"
                                    />
                                    <p>{swissquality}</p>
                                </div>
                                <div className="value-item">
                                    <img
                                        src="./assets/vector/innovation-icon.svg"
                                        alt="Innovation"
                                    />
                                    <p>{swissinnovation}</p>
                                </div>
                                <div className="value-item">
                                    <img
                                        src="./assets/vector/precision-icon.svg"
                                        alt="Precision"
                                    />
                                    <p>{swissprecision}</p>
                                </div>
                            </div>
                            <a
                                href="https://www.crunchbase.com/organization/lvdere-gmbh"
                                className="learn-more"
                            >
                                <button className="btn learn-more-btn">
                                    {footertext}
                                </button>
                            </a>
                        </div>
                    </div>
                </Slide>

                <hr />

                <div className="nav">
                    <div className="logo">
                        <img src="./assets/vector/logodark.svg" alt="logo" />
                    </div>

                    <ul className="nav">
                        <li className="nav-item">
                            <a href="https://www.iubenda.com/privacy-policy/94740641">
                                Privacy & Policy
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.iubenda.com/terms-and-conditions/94740641">
                                Terms & Condition
                            </a>
                        </li>
                    </ul>

                    <div className="social">
                        <div className="item">
                            <a href="https://www.linkedin.com/company/lvdere">
                                <img
                                    src="./assets/vector/linkedin.svg"
                                    alt="linkedin"
                                />
                            </a>
                        </div>
                        <div className="item">
                            <a href="https://www.tiktok.com/@lvdere">
                                <img
                                    src="./assets/vector/tiktok.svg"
                                    alt="tiktok"
                                />
                            </a>
                        </div>
                        <div className="item">
                            <a href="https://instagram.com/lvdere">
                                <img
                                    src="./assets/vector/instagram.svg"
                                    alt="instagram"
                                />
                            </a>
                        </div>
                        <div className="item">
                            <a href="https://discord.gg/hzHzTN5naM">
                                <img
                                    src="./assets/vector/discord.svg"
                                    alt="discord"
                                />
                            </a>
                        </div>
                        <div className="item">
                            <a href="https://wa.me/message/D6LL2W3L7WMJL1">
                                <img
                                    src="./assets/vector/whatsapp.svg"
                                    alt="whatsapp"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
