import React from 'react'
import '../../../src/styles/axrestaurant/axrestaurant-0907.css'
import { motion } from 'framer-motion'

const AXRestaurant0905 = () => {
    const shareOnWhatsApp = () => {
        const message = encodeURIComponent(
            '🍕 Entdecke die leckere Pizza von Santa Lucia Niederdorf! Komm vorbei und geniesse mit uns! https://www.bindella.ch/gastronomie/santa-lucia-niederdorf'
        )
        window.open(`https://wa.me/?text=${message}`, '_blank')
    }

    return (
        <div className="santalucianiederdorf-page">
            <header className="santalucianiederdorf-header">
                <img
                    src="/assets/img/axrestaurant/axrestaurant0907-santalucianiederdorf/axrestaurant0907-logo.svg"
                    alt="Vito Logo"
                />
            </header>

            <motion.section
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="santalucianiederdorf-branch-section"
            >
                <h2>
                    Merci Vielmals, dass du bei uns im Santa Lucia Niederdorf
                    gegessen hast!
                </h2>
                <a
                    href="https://search.google.com/local/writereview?placeid=ChIJi7Jik6mgmkcRZeF32YkNNKc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="santalucianiederdorf-btn"
                >
                    Bewertung auf Google ⭐⭐⭐⭐⭐
                </a>
            </motion.section>

            <motion.section
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="santalucianiederdorf-gutschein"
            >
                <h2>Verschenke Freude mit einem Bindella Gutschein!</h2>
                <a
                    href="https://gutscheine.bindella.ch/ki/Gutscheine.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="santalucianiederdorf-btn"
                >
                    Jetzt Gutschein kaufen 🍕
                </a>

                <button
                    className="santalucianiederdorf-btn"
                    onClick={shareOnWhatsApp}
                >
                    Lade Freunde ein 🎉
                </button>

                <a
                    href="https://www.instagram.com/santaluciaschweiz"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="santalucianiederdorf-btn"
                >
                    Folge uns auf Instagram 📸
                </a>
            </motion.section>

            <motion.section
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="santalucianiederdorf-job-section"
            >
                <h2>Werde Teil unseres Teams!</h2>
                <a
                    href="https://jobs.bindella.ch/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="santalucianiederdorf-btn"
                >
                    Jetzt bewerben 🚀
                </a>
            </motion.section>

            <footer className="santalucianiederdorf-footer">
                <p>
                    The Digitalization of this Restaurant is sponsored by
                    <a
                        href="https://lvdere.odoo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {' '}
                        Lvdere
                    </a>
                </p>
            </footer>
        </div>
    )
}

export default AXRestaurant0905
