import React from 'react'
import '../../styles/axrestaurant/axrestaurant-0905.css'
import { motion } from 'framer-motion'

const AXRestaurant0905 = () => {
    const shareOnWhatsApp = () => {
        const message = encodeURIComponent(
            '🍕 Entdecke die leckere Pizza von Vito! Komm vorbei und geniesse mit uns!'
        )
        window.open(`https://wa.me/?text=${message}`, '_blank')
    }

    return (
        <div className="vito-page">
            <header className="vito-header">
                <img
                    src="/assets/img/axrestaurant/axrestaurant0905-vito/axrestaurant0905-logo.svg"
                    alt="Vito Logo"
                />
            </header>

            <motion.section
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="vito-branch-section"
            >
                <h2>
                    Merci Vielmals, dass du bei uns am Limmatplatz gegessen
                    hast!
                </h2>
                <a
                    href="https://search.google.com/local/writereview?placeid=ZlRTHtp3W3SvpJJTh3jn8Q"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="vito-btn"
                >
                    Bewertung auf Google ⭐⭐⭐⭐⭐
                </a>
            </motion.section>

            <motion.section
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="vito-gutschein"
            >
                <h2>Verschenke Freude mit einem Pizza-Gutschein!</h2>
                <a
                    href="https://shop.e-guma.ch/vito/de/gutscheine"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="vito-btn"
                >
                    Jetzt Gutschein kaufen 🍕
                </a>

                <button className="vito-btn" onClick={shareOnWhatsApp}>
                    Lade Freunde ein 🎉
                </button>

                <a
                    href="https://www.instagram.com/vito.pizza"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="vito-btn"
                >
                    Folge uns auf Instagram 📸
                </a>
            </motion.section>

            <motion.section
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="vito-job-section"
            >
                <h2>Werde Teil unseres Teams!</h2>
                <a
                    href="https://vito.ch/jobs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="vito-btn"
                >
                    Jetzt bewerben 🚀
                </a>
            </motion.section>

            <footer className="vito-footer">
                Digitalisierung durch Lvdere
            </footer>
        </div>
    )
}

export default AXRestaurant0905
