import React from 'react'
import Navbar from '../components/Navbar'
import CareersContact from '../components/CareersContact'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'

function Careers() {
    const { t } = useTranslation()

    // Service contact data
    const serviceContacts = [
        {
            cta: 'https://forms.office.com/e/W5svWc4VCK',
            icon: './assets/vector/feature-ai.svg',
            title: t('servicecontact.icon1title') || 'AI Careers',
            description:
                t('servicecontact.icon1description') ||
                'Join our AI-driven innovation team.',
        },
        {
            cta: 'https://forms.office.com/e/NHkZXQfv9V',
            icon: './assets/vector/feature-ads.svg',
            title: t('servicecontact.icon2title') || 'Marketing Careers',
            description:
                t('servicecontact.icon2description') ||
                'Shape the future of digital advertising.',
        },
    ]

    return (
        <div>
            {/* Render Navbar */}
            <Navbar
                menu1={t('navbar.menu1') || 'Communities'}
                menu2={t('navbar.menu2') || 'Careers'}
                menu3={t('navbar.menu3') || '4 Investors'}
                menu4={t('navbar.menu4') || 'Contact Us'}
            />

            {/* Render Careers Contact Section */}
            <CareersContact
                title={t('servicecontact.title') || 'Join Our Team'}
                subtitle={
                    t('servicecontact.description') ||
                    'Discover exciting opportunities with us.'
                }
                start={t('servicecontact.start') || 'Apply Now'}
                contacts={serviceContacts}
            />

            {/* Render Footer */}
            <Footer
                subtitle={t('footerhome.subtitle')}
                title={t('footerhome.title')}
                description={t('footerhome.description')}
                cta={t('footerhome.cta')}
                swissfooter={t('footerhome.swissfooter')}
                swissquality={t('footerhome.swissquality')}
                swissinnovation={t('footerhome.swissinnovation')}
                swissprecision={t('footerhome.swissprecision')}
                footertext={t('footerhome.footertext')}
            />
        </div>
    )
}

export default Careers
