import React from 'react'
import ImageSlider from './ImageSlider' // Import the ImageSlider
import Fade from 'react-reveal/Fade'
import '../styles/Portfolio.css'

const Portfolio = ({ title, subtitle, courses }) => {
    return (
        <section id="portfolio" className="portfolio-section">
            <div className="container">
                <div className="header">
                    <h1 className="portfolio-title">{title}</h1>
                    <p className="portfolio-subtitle">{subtitle}</p>
                </div>
                <div className="portfolio-cards">
                    {courses.map((course, index) => (
                        <Fade key={index}>
                            <div className="card portfolio-card">
                                <div className="card-image">
                                    <ImageSlider
                                        image1={course.image1}
                                        image2={course.image2}
                                    />
                                </div>
                                <div className="card-content">
                                    <h3 className="card-title">
                                        {course.title}
                                    </h3>
                                    <h5 className="card-subtitle">
                                        {course.subtitle}
                                    </h5>
                                    <p className="card-description">
                                        {course.description}
                                    </p>
                                </div>
                                <div className="card-actions">
                                    <a
                                        href={course.ctalink1}
                                        className="btn btn-primary"
                                    >
                                        {course.ctatext1}
                                    </a>
                                    <a
                                        href={course.ctalink2}
                                        className="btn btn-secondary"
                                    >
                                        {course.ctatext2}
                                    </a>
                                </div>
                            </div>
                        </Fade>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Portfolio
